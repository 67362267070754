<!--
 * @Author: your name
 * @Date: 2021-10-26 15:02:33
 * @LastEditTime: 2022-04-11 11:06:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web-exam\src\views\list\index.vue
-->
<template>
    <div class="exam-list">
        <div class="list-header">
            <Nav></Nav>
            <!-- <div class="list-nav">
                <div class="nav-left"><img src="../../assets/list/newv.jpg" alt="" /></div>
                <div class="nav-right">
                  <div class="nav-menu">
                    <div class="menu-item" v-for="(item,index) in navList" :key="index">{{item.name}}</div>                 
                  </div>                                    
                </div>
            </div> -->
            <div class="list-banner">
                <div class="exam-search">
                    <div class="search-header">资讯中心</div>
                    <div class="search-input-modal">
                        <el-input placeholder="输入关键词进行搜索" type="text" v-model="listQuery.newsSubject" class="search-input" />
                        <el-button type="primary" @click="searchList" class="search-btn">
                            <div class="btn-text">
                                <img src="../../assets/list/search-btn.png" alt="" />
                                <span>搜索</span>
                            </div>
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-main">
            <!-- <div class="main-header">
              <div style="display:flex;">                
                <div class="content-btn" :class="[activeType == 'make' && 'select']" @click="makeExam('make')">在线考试</div>
                <div class="content-btn" :class="[activeType == 'makeUp' && 'select']" @click="makeExam('makeUp')">考试补考</div>
              </div>
                <div class="file-btn">
                  <span style="margin-right:8px;">
                    考试档案
                  </span>
                  <img src="../../assets/list/arrows-right.png" alt="">
                </div>
            </div> -->
            <div class="main-content">
                <el-table :show-header="false" empty-text="暂无数据" class="customer-table" :data="tableData" stripe style="width: 100%">
                    <el-table-column header-align="center">
                        <template slot-scope="scope">
                            <div @click="goDetail(scope.row)">
                                <span style="padding-left: 32px; margin-right: 16px" class="exam-name">{{ scope.row.newsSubject }}</span>
                                <el-tag v-if="scope.row.isTop" effect="dark"> 置顶 </el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="right">
                        <template slot-scope="scope">
                            <div @click="goDetail(scope.row)">
                                <span style="padding-right: 32px">{{ moment(scope.row.publishTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') }}</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="height: 30px; margin: 36px auto; display: flex; justify-content: center; align-items: center">
                    <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" background layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
                </div>
            </div>
            <div class="main-center-border"></div>
        </div>
    </div>
</template>

<script>
import Nav from '@/components/nav.vue';
import Api from '@/api/news';
import moment from 'moment';

export default {
    components: { Nav },
    data() {
        return {
            total: 0,
            navList: [{ name: '首页' }, { name: '学习中心' }, { name: '考试中心' }, { name: '资源中心' }, { name: '调查问卷' }, { name: '职业发展' }, { name: '审批中心' }],
            activeIndex: '1',
            activeIndex2: '1',
            page: 1,
            pageSize: 10,
            tableData: [],
            listQuery: {
                SkipCount: 0,
                MaxResultCount: 10,
                newsSubject: '',
                newsTypeCode: 'news',
                Sorting: 'IsTop desc,PublishTime desc',
            },
            activeType: 'make',
        };
    },
    methods: {
        moment,
        goDetail(item) {
            this.$router.push(`/newsList/detail?id=${item.id}`);
        },
        searchList() {
            this.makeExam('make');
        },
        makeExam(type) {
            this.activeType = type;
            this.requestList();
            this.$forceUpdate();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.requestList();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.requestList();
        },

        requestList() {
            if (this.pageSize) {
                this.listQuery.MaxResultCount = this.pageSize;
            }
            this.listLoading = true;
            this.listQuery.SkipCount = (this.page - 1) * this.listQuery.MaxResultCount;
            Api.getList(this.listQuery)
                .then(res => {
                    console.warn(res);
                    console.warn(res.data.items);
                    this.tableData = res.data.items;
                    this.total = res.data.totalCount;
                    // this.tableData.forEach(item => {
                    //     if (item.beginTimeFormat) {
                    //         console.warn(item.beginTimeFormat.split(' ')[1].split(':'));
                    //         item.beginTimeFormat = item.beginTimeFormat.split(' ')[0] + ' ' + item.beginTimeFormat.split(' ')[1].split(':')[0] + ':' + item.beginTimeFormat.split(' ')[1].split(':')[1];
                    //         console.warn(item);
                    //     }
                    //     if (item.endTimeFormat) {
                    //         console.warn(item.endTimeFormat.split(' ')[1].split(':'));
                    //         item.endTimeFormat = item.endTimeFormat.split(' ')[0] + ' ' + item.endTimeFormat.split(' ')[1].split(':')[0] + ':' + item.endTimeFormat.split(' ')[1].split(':')[1];
                    //         console.warn(item);
                    //     }
                    // });
                })
                .catch(err => {
                    console.warn(err);
                });
        },
    },
    created() {
        if (this.$route.query.name) {
            this.listQuery.newsSubject = this.$route.query.name;
        }
        this.requestList();
    },
    watch: {
        $route: {
            handler(newVal, oldVal) {
                if (newVal.query.name) {
                    this.listQuery.newsSubject = newVal.query.name;
                } else {
                    this.listQuery.newsSubject = '';
                }
                this.searchList();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
    height: 100%;
    border: none;
    padding-left: 32px;
}
.el-menu {
    background-color: initial;
}
.exam-list {
    .list-header {
        height: 490px;
        background: url('../../assets/list/BG.png');

        .list-banner {
            height: 400px;
            position: relative;
            .exam-search {
                width: 1300px;
                margin: 0 auto;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                left: 50%;
                .search-header {
                    font-size: 56px;
                    // font-family: PingFang-SC-Bold, PingFangSC-Regular;
                    font-weight: 400;
                    text-align: left;
                    color: #1e2046;
                    line-height: 64px;
                    margin-bottom: 16px;
                }
                .search-desc {
                    font-size: 16px;
                    // font-family: PingFang-SC-Bold, PingFangSC-Light;
                    font-weight: 300;
                    text-align: left;
                    color: #1e2046;
                    line-height: 66px;
                }
                .search-input-modal {
                    display: flex;
                    width: 650px;
                    height: 70px;
                    position: relative;
                    background: #ffffff;
                    border-radius: 15px;
                    overflow: hidden;
                    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
                    .search-btn {
                        width: 170px;
                        height: 100%;
                        z-index: 99;
                        position: absolute;
                        right: 0;
                        .btn-text {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            span {
                                margin-left: 16px;
                                height: 28px;
                                font-size: 20px;
                                // font-family: PingFangSC, PingFangSC-Medium;
                                font-weight: 500;
                                text-align: left;
                                color: #ffffff;
                                line-height: 28px;
                            }
                        }
                    }
                }
            }
        }
    }
    .list-main {
        width: 1300px;
        margin: 40px auto 20px;
        .exam-name {
            font-size: 14px;
            font-weight: 600;
            // font-family: PingFangSC, PingFangSC-Medium;
            text-align: left;
            color: #000000;
            line-height: 15px;
        }
        .handle-btn {
            width: 84px;
            height: 30px;
            border: 1px solid #979797;
            border-radius: 16px;
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            &:hover {
                background: #1fa3ff;
                color: #fff;
                border: none;
            }
        }
        .main-header {
            display: flex;
            margin-bottom: 18px;
            justify-content: space-between;
            align-items: center;
            .content-btn {
                width: 132px;
                height: 46px;
                border-radius: 25px;
                line-height: 46px;
                text-align: center;
                font-size: 20px;
                font-weight: 500;
                background: #f5f5f7;
                color: #2d2d2d;
                margin-right: 35px;
                &.select {
                    background: rgba(31, 163, 255, 0.09);
                    color: #1fa3ff;
                }
            }
            .file-btn {
                width: 126px;
                height: 42px;
                // opacity: 0.47;
                border: 1px solid #cacaca;
                font-size: 16px;
                // font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                color: #000000;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .main-content {
            // min-height: 275px;
            height: auto;
            background: linear-gradient(136deg, rgba(255, 255, 255, 0.82), rgba(255, 255, 255, 0.8) 100%);
            border-radius: 15px;
            box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.04);
            overflow: hidden;
        }
        .main-center-border {
            width: 1300px;
            height: 2px;
            opacity: 0.1;
            background: #3e4294;
            margin: 28px auto 0;
        }
        .main-copyright {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            // font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            color: #a5a5a5;
            line-height: 22px;
        }
    }
}
</style>
<style>
/* .main-content .el-table th.is-leaf, */
.main-content .el-table td,
.main-content .el-table--border,
.main-content .el-table__body-wrapper {
    /* grey */
    border: none;
    /* cursor: pointer; */
}
.main-content .el-table::before {
    height: 0;
}
</style>